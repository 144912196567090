<template>
  <div>
    <TopNavVue/>
    <div class="box">
      <el-row class="picbox">
        <img src="@/assets/dxyy/dx1.jpg" />
      </el-row>
      <el-row>
        <el-col :span="22" :offset="1">
          <h6 class="title">应用场景</h6>
          <p class="tied">磐石云桌面为用户提供更灵活、以人为中心的计算体验，用户可以通过任何设备随时随地轻松访问完整的虚拟桌面、应用和数据，实现更高效的移动虚拟工作方式。快速灵活的桌面交付可以帮助IT部门快速适应业务变化，集中桌面管理、交付和控制使您可以重新审视原有的桌面计算方法，实现更高的移动性和安全性并降低成本。</p>
        </el-col>
        <el-col :span="22" :offset="1">
          <h6 class="title">典型应用</h6>
          <div class="dxa">

            <div class="dxb">
              <div class="imgbox">
                <img src="@/assets/dxyy/13.png"/>
              </div>
              <h6>政务办公</h6>
              <p>通过多重身份认证机制，保障接入安全，结合政务数据的集中存储机制及精细化的外设管控策略，实现数据和业务分离，完善的可视化管理能力；运维审计、监控、双网或多网隔离模式等手段，有效防范数据外泄和违规外联（非法外联），保障安全合规，提高了业务系统的可用性及可靠性。</p>
            </div>
            <div class="dxb">
              <div class="imgbox">
                <img src="@/assets/dxyy/12.png"/>
              </div>
              <h6>金融保险</h6>
              <p>金融企业的大部分业务系统都是自主开发的，由于企业自身的开发资源有限，往往引进软件开发外包团队进行协作开发。这些业务系统的代码是金融企业的核心资产，同时这些代码也涉及到金融交易的安全性，所以必须严格保证代码的安全性。针对这种业务场景，磐石提供了基于云桌面和网闸的开发桌面云方案。</p>
            </div>
            <div class="dxb">
              <div class="imgbox">
                <img src="@/assets/dxyy/14.png"/>
              </div>
              <h6>专业设计</h6>
              <p>磐石云桌面GPU虚拟化（vGPU）可帮助用户实现对物理GPU的资源切割，实现和物理GPU完全一致的功能，支持主流的3D设计类软件，满足用户对GPU在图形、计算机人工智能方面的需求，并且提供易管理、高性价比、安全有保障的设计云桌面。</p>
            </div>
            <div class="dxb">
              <div class="imgbox">
                <img src="@/assets/dxyy/15.png"/>
              </div>
              <h6>软件研发</h6>
              <p>磐石云桌面为软件研发人员提供了一套前后端软硬件深度融合的云桌面解决方案，将研发工作环境、项目代码、过程文档在数据中心进行集中存储和管理，开发终端不留存任何内部数据，通过合理控制权限、外设精细化管控等安全策略，有效防范研发代码及开发文档等敏感数据外泄，同时满足开发人员对系统性能和数据安全可靠的要求。</p>
            </div>
            <div class="dxb">
              <div class="imgbox">
                <img src="@/assets/dxyy/16.png"/>
              </div>
              <h6>教育培训</h6>
              <p>大幅度降低教室PC运维工作量，支持移动教学备课跨平台资源共享，使学生和教职员工能够通过移动设备安全地按需接入所需的应用、数据和服务，进而推动自学和探究式学习，打造新型信息化教育模式。</p>
            </div>
            <div class="dxb">
              <div class="imgbox">
                <img src="@/assets/dxyy/11.png"/>
              </div>
              <h6>航天军工</h6>
              <p>大型国有航天军工单位，因为组织的特殊性，在承担重大技术研发任务时，对信息安全和保密工作有着最高级别的要求。磐石云桌面将系统和数据都集中存放在服务器上，并且所有终端电脑只连接内网，在通过磐石外设管理系统管控所有外部存储接口，实现数据不落地，安全不外泄。</p>
            </div>

          </div>
        </el-col>
      </el-row>
    </div>
    <footerMobileVue/>
  </div>
</template>

<script>
import TopNavVue from '@/components/topNav.vue'
import footerMobileVue from '@/components/footerMobile.vue';
export default {
  name:'dxyyMobile',
  components:{footerMobileVue,TopNavVue},

}
</script>

<style scoped>
  .clearfix{clear: both;}
  .box{background: #F6F6F6;padding-top: 12%;}
  .picbox{width: 100%;}
  .picbox img{width: 100%;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;margin-top: 20px;}
  .tied{font-size: 3vw;color: #888;display: block;width: 100%;margin: 15px auto 0px;text-align: justify;text-indent: 2em;}
  .dxa{margin-top: 15px;}
  .dxb{width: 100%;margin-bottom: 15px;padding-bottom: 15px; box-shadow: 0px 0px 10px #ccc;}
  .imgbox img{width: 100%;}
  .dxb h6{font-size: 4vw;color: #333;display: block;margin-left: 2%;margin-top: 10px;margin-bottom: 5px;}
  .dxb p{font-size: 3vw;color: #888;display: block;width: 96%;margin: 15px auto 0px;text-align: justify;text-indent: 2em;}
</style>